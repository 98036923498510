/**
 * Main front end point of entry.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import ReactDOM from "react-dom";
import App from "app/App";

import "style/reset.scss";
import "style/main.scss";

import axios from "axios";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "api/endpoints";
import * as noomscape from "@noom/noomscape";
import { ThemeProvider, LoaderView } from "@noom/wax-component-library";

// Configure libraries
axios.defaults.headers.post["Content-Type"] = "application/json";
noomscape.Controller.setGlobalLoadingComponent(LoaderView);

// Render the app
ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
