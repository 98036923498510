/**
 * Controller for the main layout.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import { Controller, handler } from "@noom/noomscape";
import { withRouter } from "react-router-dom";
import UserProfileSingleton from "model/collections/UserProfileSingleton";

import MainLayout from "views/main/MainLayout/MainLayout";

class MainController extends Controller {
  mainComponent = MainLayout;

  subscribe = (onData) => {
    UserProfileSingleton.addChangeListener(onData);
  };

  unsubscribe = (onData) => {
    UserProfileSingleton.removeChangeListener(onData);
  };

  fetch = () => {
    UserProfileSingleton.fetch();
  };

  @handler
  onLogout = () => {
    this.props.history.push("/logout");
  };

  getData = () => {
    return {
      user: UserProfileSingleton.get(),
    };
  };

  isReady = () => {
    return !!this.state.user;
  };
}

export default withRouter(MainController);
