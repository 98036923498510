/**
 * Main React component.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import {
  AuthContext,
  SecureRoute,
  Login,
  LoginCallback,
  Logout,
} from "@noom/hive-auth";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IconLibrary, ModalContext, ToastContext } from "@noom/noomscape";
import { LoaderView } from "@noom/wax-component-library";

import MainController from "views/main/MainLayout/MainController";
import HomeController from "views/main/HomePage/HomeController";

import CDUListController from "views/cdu/CDUListPage/CDUListController";
import CDUEditController from "views/cdu/CDUEditPage/CDUEditController";
import UserProfileController from "views/user/UserProfilePage/UserProfileController";
import AssignmentsHomeController from "views/assignments/AssignmentsHomePage/AssignmentsHomeController";
import VacationHomeController from "views/vacation/VacationHomePage/VacationHomeController";
import CoverageHomeController from "views/coverage/CoverageHomePage/CoverageHomeController";
import GroupCreatorController from "views/groupCreator/GroupCreatorController";
import OffboardingController from "views/offboarding/OffboardingController";
import BulkAssignmentController from "views/bulkAssignement/BulkAssignmentController";
import UserCheckerController from "views/groupMembershipChecker/UserCheckerController";
import GroupRemoverController from "views/groupRemover/GroupRemoverController";

const App = () => {
  const oneLoginClientId = process.env.HIVE_ONE_LOGIN_CLIENT_ID ?? "";

  return (
    <AuthContext
      clientId={oneLoginClientId}
      CSClientId="coach-dashboard"
      fallback={<LoaderView />}
    >
      <ToastContext>
        <ModalContext>
          <BrowserRouter>
            <Switch>
              <Route path="/login/callback">
                <LoginCallback />
              </Route>

              <Route path="/login">
                <Login />
              </Route>

              <Route path="/logout">
                <Logout />
              </Route>

              <SecureRoute path="/">
                <MainController>
                  <Switch>
                    <Route path="/" exact>
                      <HomeController />
                    </Route>

                    <Route path="/cdu" exact>
                      <CDUListController />
                    </Route>

                    <Route path="/cdu/:cduAccessCode">
                      <CDUEditController />
                    </Route>

                    <Route path="/user">
                      <UserProfileController />
                    </Route>

                    <Route path="/vacation">
                      <VacationHomeController />
                    </Route>

                    <Route path="/assignments">
                      <AssignmentsHomeController />
                    </Route>

                    <Route path="/group-creation">
                      <GroupCreatorController />
                    </Route>

                    <Route path="/group-remover">
                      <GroupRemoverController />
                    </Route>

                    <Route path="/offboarding">
                      <OffboardingController />
                    </Route>

                    <Route path="/coverage">
                      <CoverageHomeController />
                    </Route>

                    <Route path="/bulk-assignment">
                      <BulkAssignmentController />
                    </Route>

                    <Route path="/user-checker">
                      <UserCheckerController />
                    </Route>
                  </Switch>
                </MainController>
              </SecureRoute>
            </Switch>
          </BrowserRouter>
          <IconLibrary />
        </ModalContext>
      </ToastContext>
    </AuthContext>
  );
};

export default App;
