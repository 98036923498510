/**
 * Collection for physical coaches (CDUs)
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author Hubert
 */

import _ from "lodash";
import { Collection, DevTools, Api } from "@noom/noomscape";

import CDU from "model/items/CDU";
import Schedule from "model/items/Schedule";
import Employment from "model/items/Employment";

class CDUCollection extends Collection {
  constructor() {
    super();
    CDU.store = this;
  }

  lists = {};

  clear() {
    super.clear();
    this.lists = {};
  }

  /*
    ============================================================
    Fetchers
  */

  fetchWithFilter = ({ filter }) => {
    const f = filter.toString();
    if (this.lists[f]) {
      return;
    }

    Api.call("cdu.filter", Api.api.cduSearch, {
      query: filter.query || "",
      showInactive: filter.showInactive || false,
      needsAttention: filter.needsAttention || false,
      showNmcs: filter.showNmcs || false,
      minEmploymentStartDate:
        filter.startDate && filter.startDate.format("YYYY-MM-DD"),
      maxEmploymentStartDate:
        filter.endDate && filter.endDate.format("YYYY-MM-DD"),
    })
      .then((data) => {
        const list = [];
        data.forEach((raw) => {
          const cdu = this.insert(new CDU(raw));
          list.push(cdu.key);
        });
        this.lists[f] = _.sortBy(list, (key) => this.items[key].sortBy);
        this.clearCache(f);
        this.emitChange();
      })
      .catch((error) => {
        console.log("CDU FILTER ERROR", error);
      });
  };

  fetchOne = (accessCode, options) => {
    Api.call("cdu.one", Api.api.cduOne, {
      cduAccessCode: accessCode,
    })
      .then((data) => {
        const cdu = new CDU(data);
        this.insert(cdu);

        if (options && options.complete) {
          this.fetchMoreData(accessCode);
        }

        this.emitChange();
      })
      .catch((error) => {
        console.log("CDU ONE ERROR", error);
      });
  };

  fetchMoreData = (accessCode) => {
    const cdu = this.items[accessCode];

    const multi = Api.multi(`cdu.${cdu.accessCode}.fetchData`);

    multi.push(Api.api.cduDataEmployment, {
      cduAccessCode: accessCode,
    });

    multi.push(Api.api.cduDataSchedules, {
      cduAccessCode: accessCode,
    });

    multi.push(Api.api.cduDataTableaus, {
      cduAccessCode: accessCode,
    });

    if (cdu.managerAccessCode) {
      multi.push(Api.api.cduOne, {
        cduAccessCode: cdu.managerAccessCode,
      });
    }

    multi
      .run()
      .then((dataArray) => {
        const [
          employment,
          allSchedules,
          allTableaus,
          { firstName: managerFirstName, lastName: managerLastName } = {},
        ] = dataArray;

        multi.done();

        cdu.setFromRaw({
          employment,
          allSchedules,
          allTableaus,
          managerFirstName,
          managerLastName,
        });
      })
      .catch((err) => {
        console.log("AN ERROR OCCURED IN CDU COMPLETE MULTI", err);
        multi.error();
        throw err;
      });
  };

  /*
    ============================================================
    Getters
  */

  getWithFilter = ({ filter }) => {
    const f = filter.toString();
    if (!this.cache[f]) {
      this.initCache({
        cacheKey: f,
        itemList: _.map(this.lists[f], (accessCode) => this.items[accessCode]),
      });
    }
    return this.cache[f];
  };

  /*
    ============================================================
    Updaters
  */

  updateWhiteData = ({ cduAccessCode, data }) => {
    const cdu = this.items[cduAccessCode];
    const multi = Api.multi(`cdu.${cduAccessCode}.updateWhiteData`);

    const cduProperties = [
      "email",
      "namelyEmployeeId",
      "isActive",
      "managerAccessCode",
    ];

    // Figure out if CDU needs to be updated
    if (_.intersection(_.keys(data), cduProperties).length > 0) {
      multi.push(Api.api.cduUpdate, {
        cduAccessCode,
        data: _.assign({}, cdu, _.pick(data, cduProperties)),
      });
    }

    // Figure out schedule operations
    if (data.schedule) {
      const newSchedule = new Schedule(
        _.assign({}, cdu.schedule, data.schedule)
      );
      if (cdu.schedule) {
        if (!cdu.schedule.isEqualTo(newSchedule)) {
          // Need to update schedule
          multi.push(Api.api.cduDataUpdateSchedule, {
            cduAccessCode,
            schedule: newSchedule,
          });
        }
      } else {
        // We have no schedule yet, will create a new one
        multi.push(Api.api.cduDataCreateSchedule, {
          cduAccessCode,
          schedule: newSchedule,
        });
      }
    }

    // Figure out employment operations
    if (
      data.rampUp ||
      data.rampUp === false ||
      data.employmentStartDate ||
      data.language ||
      data.hasFixedSchedule ||
      data.hasFixedSchedule === false
    ) {
      if (cdu.employment) {
        multi.push(Api.api.cduDataEmploymentUpdate, {
          cduAccessCode,
          employmentId: cdu.employment.id,
          data: {
            rampUpType: Employment.rampUpTypeForRampUp(data.rampUp),
            startDate: data.employmentStartDate,
            language: data.language,
            hasFixedSchedule: data.hasFixedSchedule,
          },
        });
      } else {
        multi.push(Api.api.cduDataCreateEmployment, {
          cduAccessCode,
          data: {
            rampUpType: Employment.rampUpTypeForRampUp(data.rampUp),
            startDate: data.employmentStartDate,
            language: data.language,
            hasFixedSchedule: data.hasFixedSchedule,
          },
        });
      }
    }

    // Figure out baseline tableau operations
    if (
      data.baselineTableau &&
      !(
        cdu.baselineTableau &&
        cdu.baselineTableau.isEqualToFormData(data.baselineTableau)
      )
    ) {
      // Update or create baseline tableau
      multi.push(Api.api.cduDataUpdateBaselineTableau, {
        cduAccessCode,
        tableau: _.assign({}, cdu.baselineTableau, data.baselineTableau),
      });
    }

    // Figure out temporary tableau operations
    if (data.temporaryTableau) {
      if (cdu.temporaryTableau) {
        // Update temporary tableau
        multi.push(Api.api.cduDataUpdateTableau, {
          cduAccessCode,
          tableau: _.assign({}, cdu.temporaryTableau, data.temporaryTableau),
          tableauId: cdu.temporaryTableau.allocationUuid,
        });
      } else {
        // Create temporary tableau
        multi.push(Api.api.cduDataCreateTableau, {
          cduAccessCode,
          tableau: data.temporaryTableau,
        });
      }
    } else {
      if (cdu.temporaryTableau) {
        // Remove temporary tableau
        multi.push(Api.api.cduDataRemoveTableau, {
          cduAccessCode,
          tableauUuid: cdu.temporaryTableau.allocationUuid,
        });
      }
    }

    return multi
      .run()
      .then(() => {
        multi.done();
      })
      .catch((err) => {
        console.log("AN ERROR OCCURED IN MULTI", err);
        multi.error();
        throw err;
      });
  };
}

const instance = new CDUCollection();
DevTools.expose({ CDUCollection: instance });
export default instance;
