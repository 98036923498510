/**
 * Controller for the main page.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import { Controller } from "@noom/noomscape";
import { withRouter } from "react-router-dom";

class HomeController extends Controller {
  mainComponent = null;
  controllerWillMount = () => {
    this.props.history.push("/cdu");
  };

  fetch = () => {};
  getData = () => ({});
  isReady = () => false;
}

export default withRouter(HomeController);
